import React, { useEffect, useState } from "react";
import CardHeadingChildButtons from "../UI/CardHeadingChildButtons";
import { SearchIcon, XIcon, PlusIcon, ReplyIcon } from "@heroicons/react/outline";
import { Link, useParams } from "react-router-dom";
import Button from "../UI/Button";
import PodcastCard from "../UI/PodcastCard";
import YouTubeCard from "./YouTubeSearchResults";
import { axiosPrivate } from "../common/axiosPrivate";
import { BarLoader } from "react-spinners";
import { useReducer } from "react";
import SearchInput from "../UI/SearchComponent";
import SEO from "./SEO";
import useLibraryData from "../hooks/useLibraryData";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

//reducer

const FETCH_DATA = "FETCH_DATA";
const FILTER_FEEDS = "FILTER_FEEDS";
const SHOW_MORE = "SHOW_MORE";
const SORT_FAVORITES = "SORT_FAVORITES";

const initialState = {
  feeds: [],
  filteredFeeds: [],
  displayCount: 5, // New state for tracking display count
};

const feedsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_DATA:
      return {
        ...state,
        feeds: action.payload,
        filteredFeeds: action.payload.slice(0, state.displayCount),
      };
    case FILTER_FEEDS:
      const filteredFeeds = action.payload
        ? state.feeds.filter((feed) => feed.title.toLowerCase().includes(action.payload.toLowerCase()))
        : state.feeds;
      return {
        ...state,
        filteredFeeds: filteredFeeds.slice(0, state.displayCount),
      };
    case SHOW_MORE:
      return {
        ...state,
        displayCount: state.displayCount + 5,
      };
    case SORT_FAVORITES:
      const sortedFeeds = [...state.feeds].sort((a, b) => {
        if (a.is_favorite === b.is_favorite) return 0;
        return a.is_favorite ? -1 : 1;
      });
      return {
        ...state,
        feeds: sortedFeeds,
        filteredFeeds: sortedFeeds.slice(0, state.displayCount),
      };
    case "UPDATE_LIBRARY_STATUS":
      const { itemId, isAdded } = action.payload;
      const updatedFeeds = state.feeds.map((item) => {
        if (item.id === itemId || (item.id && item.id.videoId === itemId)) {
          return { ...item, is_added_to_library: isAdded };
        }
        return item;
      });
      return {
        ...state,
        feeds: updatedFeeds,
        filteredFeeds: updatedFeeds.slice(0, state.displayCount),
      };
    default:
      return state;
  }
};

// Module scoped variables

export default function DiscoverSearchResults(props) {
  const { searchType, searchTerm } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [state, dispatch] = useReducer(feedsReducer, initialState);
  const [filterTerm, setFilterTerm] = useState("");
  const { LibraryData, setLibraryData, refreshLibraryEpisode, removeLibraryEpisode, addLibraryEpisode } =
    useLibraryData();

  console.log("Search type:", searchType);
  console.log("Search term:", searchTerm);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let response;
        if (searchType === "podcast") {
          response = await axiosPrivate.post(`/podcast/search`, {
            query: searchTerm,
          });
          dispatch({ type: FETCH_DATA, payload: response.data.podcasts });
        } else if (searchType === "youtube") {
          response = await axiosPrivate.post(`/youtube/search`, {
            query: searchTerm,
          });
          dispatch({ type: FETCH_DATA, payload: response.data.videos });
          console.log("Search results: ", response.data);
        }
        console.log("Search results: ", response.data);
        dispatch({ type: FILTER_FEEDS });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching search results: ", error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [searchType, searchTerm]);

  const handleFavoriteOnClick = async (itemId, isFavorite) => {
    try {
      if (searchType === "podcast") {
        await axiosPrivate.post(`/podcast/favorites/${itemId}`, {
          is_favorite: isFavorite,
        });
      } else if (searchType === "youtube") {
        await axiosPrivate.post(`/youtube/favorites/${itemId}`, {
          is_favorite: isFavorite,
        });
      }
    } catch (error) {
      console.error("Error updating favorite status: ", error);
    }
    setFilterTerm("");
    dispatch({ type: "FAVORITE", payload: { itemId, isFavorite } });
    dispatch({ type: FILTER_FEEDS }); //log the favorite feeds
  };

  useEffect(() => {
    console.log("Filtered feeds: ", state.filteredFeeds);
    console.log("Display count: ", state.displayCount);
    console.log("All feeds: ", state.feeds);
  }, [state.feeds]);
  const handleShowMore = (state, dispatch) => {
    dispatch({ type: SHOW_MORE });
    dispatch({ type: FILTER_FEEDS });
  };
  return (
    <>
      <SEO title="PodScribe.IO | Discover Search Results" />
      <div className="flex">
        <section
          aria-labelledby="message-heading"
          className="min-w-0 flex-1 lg:h-screen flex flex-col overflow-y-auto lg:order-last h-screen-ios h-screen">
          {/* Main Content. Note the lg:h-screen is important because of the Navigation menu */}
          <BarLoader width={"100%"} color={"#fca311"} loading={isLoading} size={150} />
          <CardHeadingChildButtons title="Podcast Search results" subHeading="">
            <SearchInput
              value={filterTerm}
              onChange={(e) => {
                dispatch({ type: FILTER_FEEDS, payload: e.target.value });
                setFilterTerm(e.target.value);
              }}
              className=""
            />
          </CardHeadingChildButtons>

          <div className="flex flex-col flex-1 lg:overflow-y-auto items-center">
            <div className="h-screen h-screen-ios w-screen md:w-8/12 lg:w-9/12 px-2 py-2">
              <h2 className="text-xl text-center text-neutral-700">
                {searchType.charAt(0).toUpperCase() + searchType.slice(1)} search results for:{" "}
                <span className="font-bold">{searchTerm}</span>
                <Link to="/discover" title="Back to Search">
                  <button className="ml-2 text-primary-600">
                    <span className="sr-only">Back to Discover</span>
                    <ReplyIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </Link>
              </h2>
              <div className="mt-2 text-sm text-center text-neutral-500 bg-blue-50 border border-blue-200 rounded-md p-2">
                <p>Note: Only English-spoken {searchType === "youtube" ? "YouTube videos" : "podcasts"} are currently supported.</p>
              </div>
              {/* Search results go here if there are*/}

              {!isLoading && state.feeds.length === 0 && (
                <div className="text-center mt-4">
                  <h3 className="text-lg text-neutral-700">No search results found.</h3>
                </div>
              )}
              <div className="mt-4 mx-3">
                {state.filteredFeeds.map((item, index) =>
                  searchType === "podcast" ? (
                    <PodcastCard key={index} feed={item} handleFavorite={handleFavoriteOnClick} />
                  ) : (
                    <YouTubeCard key={index} video={item} />
                  )
                )}
              </div>
              <div className="mt-4 mx-3 pb-12">
                {!filterTerm && !isLoading && state.displayCount < state.feeds.length && (
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                      <Button type="button" onClick={() => handleShowMore(state, dispatch)} className="py-1 gap-x-1">
                        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Load More
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
