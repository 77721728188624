import { React, useState, useEffect } from "react";
import {
  HeartIcon,
  ChevronRightIcon,
  ExternalLinkIcon,
  FolderAddIcon,
  FolderRemoveIcon,
  LightningBoltIcon,
  DownloadIcon,
} from "@heroicons/react/outline";
import { PlayIcon, PauseIcon, LightBulbIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Img from "./Img";

function Player({ url }) {
  const [playing, setPlaying] = useState(false);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  return (
    <div>
      <button onClick={handlePlayPause}>
        {playing ? (
          <PauseIcon className="h-6 w-6 text-primary-500" aria-hidden="true" />
        ) : (
          <PlayIcon className="h-6 w-6 text-primary-500" aria-hidden="true" />
        )}
      </button>
      <ReactPlayer
        url={url}
        playing={playing}
        width="0"
        height="0"
        config={{
          file: {
            forceAudio: true,
            preload: false,
          },
        }}
      />
    </div>
  );
}

export default function PodcastEpisodeCard(props) {
  const { episode } = props;

  const handleAddRemove = (feedId, episodeId) => {
    console.log("Episode: ", episode);
    props.handleAddRemove(feedId, episodeId, !episode.is_added_to_library);
  };

  useEffect(() => {
    console.log("Episode in the PodcastEpisodeCard: ", episode);
  }, [episode]);

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden transition-all duration-300 hover:shadow-lg mt-6 mb-8 sm:mb-6">
      <div className="p-4">
        <div className="flex flex-col sm:flex-row">
          <div className="flex-shrink-0 mb-4 sm:mb-0 sm:mr-4 flex justify-center">
            <Img
              src={episode.feedImage}
              alt={episode.title}
              className="h-24 w-24 sm:h-20 sm:w-20 rounded-md object-cover shadow-sm"
            />
          </div>
          <div className="flex-grow">
            <div className="flex flex-col sm:flex-row justify-between items-start">
              <Link to={episode.link} target="_blank" className="group mb-2 sm:mb-0">
                <h4 className="text-lg font-bold text-primary-600 group-hover:text-secondary-600 transition-colors duration-200">
                  {episode.title.length > 50 ? episode.title.substring(0, 50) + "..." : episode.title}
                </h4>
              </Link>
              {episode.is_added_to_library ? (
                <Link
                  to={`/library/${episode.id}`}
                  className="sm:ml-2 transition-all duration-200 px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 active:scale-95 flex items-center bg-green-100"
                >
                  <svg className="h-5 w-5 text-green-600 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="text-sm font-medium">View in Library</span>
                </Link>
              ) : (
                <button
                  onClick={() => handleAddRemove(episode.feedId, episode.id)}
                  className="sm:ml-2 transition-all duration-200 px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 active:scale-95 flex items-center bg-primary-100 hover:bg-primary-200"
                  aria-label="Add to library"
                >
                  <FolderAddIcon className="h-5 w-5 text-primary-600 mr-2" aria-hidden="true" />
                  <span className="text-sm font-medium text-primary-600">Add</span>
                </button>
              )}
            </div>
            <div className="flex flex-wrap items-center gap-3 mt-2">
              <span className="text-sm text-neutral-500">
                {new Date(episode.datePublished * 1000).toLocaleDateString("en-US")}
              </span>
              <div className="flex items-center space-x-3">
                <Link to={episode.enclosureUrl} target="_blank" className="text-primary-600 hover:text-secondary-600">
                  <DownloadIcon className="h-5 w-5" aria-hidden="true" />
                </Link>
                <Player url={episode.enclosureUrl} />
              </div>
              {episode.is_transcribed && (
                <span className="inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium bg-secondary-100 text-secondary-800">
                  <LightningBoltIcon className="h-3 w-3 mr-1" aria-hidden="true" />
                  Podscribed
                </span>
              )}
            </div>
          </div>
        </div>
        <p className="mt-3 text-sm text-neutral-600 line-clamp-3">
          {episode.description}
        </p>
        <div className="mt-4 flex justify-between items-center">
          <Link to={episode.link} target="_blank" className="text-primary-600 hover:text-secondary-600 text-sm font-medium flex items-center">
            Read more
            <ExternalLinkIcon className="ml-1 h-4 w-4" aria-hidden="true" />
          </Link>
        </div>
      </div>
    </div>
  );
}
