import react, { useEffect } from "react";
import { Fragment, useState, useRef, useMemo } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import moment, { duration } from "moment";
import InboxContent from "./InboxContent";
import ReactPlayer from "react-player";
import "react-h5-audio-player/lib/styles.css";
import "./player.css";
import { Waveform } from "../../UI/WaveForm";
import parse from "html-react-parser";
import { DocumentTextIcon, TrashIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import { ShareIcon } from "@heroicons/react/solid";
import { axiosPrivate } from "../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import useAuth from "../../hooks/useAuth";
import useUIState from "../../hooks/useUIState";
import { useCopyToClipboard } from "usehooks-ts";
import ActionModalInfo from "../../UI/ActionModalInfo";
import ActionModalPleaseSubscribeToUse from "../../UI/ActionModalPleaseSubscribeToUse";
import { Banner } from "../../UI/Banner";
import { link } from "react-router-dom";
import { ExclamationIcon } from "@heroicons/react/solid";
import {
  ArchiveIcon as ArchiveIconSolid,
  ChevronDownIcon,
  ChevronUpIcon,
  DotsVerticalIcon,
  FolderDownloadIcon,
  PencilIcon,
  ReplyIcon,
  SearchIcon,
  UserAddIcon,
  LightningBoltIcon,
  ChatAltIcon,
  MenuAlt1Icon,
  GiftIcon,
  SearchCircleIcon,
  ArrowUpIcon,
  PlusIcon,
  ThumbUpIcon,
} from "@heroicons/react/solid";
import { BarLoader, BeatLoader } from "react-spinners";
import Img from "../../UI/Img";
import PodscribeActionModal from "./PodscribeActionModal";
import { remove_html_tags } from "../../common/utils";
import { Link } from "react-router-dom";
import useLibraryData from "../../hooks/useLibraryData";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const InboxMessage = (props) => {
  const [showEditor, setShowEditor] = useState(true);
  const [showTranscribe, setShowTranscribe] = useState(true);
  const [playAudio, setPlayAudio] = useState(false);
  const [toggleEpisodeDescription, setToggleEpisodeDescription] = useState(true);
  const [TranscribeInProgress, setTranscribeInProgress] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showPodscribeModal, setShowPodscribeModal] = useState(false);
  const { auth } = useAuth();
  const [copiedText, copy] = useCopyToClipboard();
  const [shareUrl, setShareUrl] = useState("");
  const [showShareModal, setShowShareModal] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const { UIState, setUIState } = useUIState();
  const { startEpisodePolling } = useLibraryData();

  const handleInboxSidebar = () => {
    setUIState({
      ...UIState,
      inboxSidebarOpen: !UIState.inboxSidebarOpen,
    });
  };

  // handle on close the podscribe modal window
  const HandleOnClosePodscribeModal = () => {
    setShowPodscribeModal(false);
  };
  // handle on close the podscribe modal window
  const ShowPodscribeModal = () => {
    setShowPodscribeModal(true);
  };

  // useRef for the Audio Player
  const playerRef = useRef(null);

  // Function to check if the URL is a YouTube link
  const isYouTubeUrl = (url) => {
    return url.includes("youtube.com") || url.includes("youtu.be");
  };

  // Memoize the result of isYouTubeUrl
  const isYouTube = useMemo(() => isYouTubeUrl(props.episode.enclosureUrl), [props.episode.enclosureUrl]);

  // control the player based on the clicked segment

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const description = showFullDescription ? props.episode.description : `${props.episode.description.slice(0, 100)}...`;

  useEffect(() => {
    // This effect will run on every render
    setPlayAudio(false);
  }, [props.selectedEpisodeState]); // Empty dependency array means this runs once on mount and on every rerender

  const SelectSegmentHandler = (time) => {
    playerRef.current.seekTo(time, "seconds");
    // start playing
    setPlayAudio(true);
  };

  // HandleTranscript button. Show transcribe set to True or False
  const HandleShowTranscript = () => {
    setShowTranscribe(!showTranscribe);
  };

  // HandleEditor button. Show Editor set to True or False
  const HandleShowEditor = () => {
    setShowEditor(!showEditor);
  };

  // Handle Toggle Episode Description
  const HandleToggleEpisodeDescription = () => {
    setToggleEpisodeDescription(!toggleEpisodeDescription);
  };

  // Handle Transcribe button. Show transcribe set to True or False
  const HandleTranscribeEpisode = () => {
    // Calculate minutes to be used
    const minutesToUse = Math.ceil(props.episode.duration / 60);

    // send API request
    const response = axiosPrivate
      .post(`/podcast/transcription/${props.episode.id}`)
      .then((response) => {
        console.log(response);
        toast.success("Episode transcribing. Please wait a few minutes...");
        setTranscribeInProgress(true);
        // set the episode state to transcribe in progress
        props.episode.is_processing = true;
        startEpisodePolling(props.episode.feedId, props.episode.id, 5000, 300000);
        // close modal
        setShowPodscribeModal(false);
        // update the user minutes
        auth.user.podscribe_minutes = auth.user.podscribe_minutes - minutesToUse;
        if (auth.user.podscribe_minutes < 0) {
          auth.user.podscribe_topup_minutes += auth.user.podscribe_minutes;
          auth.user.podscribe_minutes = 0;
        }
      })
      .catch((error) => {
        // print error details if present
        error &&
          error.response &&
          error.response.data &&
          error.response.data.detail &&
          toast.error(error.response.data.detail);
        console.log(error);
      });
  };
  // Handle Upvote of the episode using the /podcast/upvote/<episode_id> API

  const HandleUpvoteEpisode = () => {
    // send API request
    const response = axiosPrivate
      .post(`/podcast/upvote/${props.episode.id}`)
      .then((response) => {
        console.log(response);
        toast.success("Episode Like recorded. Thank you!");
      })
      .catch((error) => {
        // print error details if present
        error &&
          error.response &&
          error.response.data &&
          error.response.data.detail &&
          toast.error(error.response.data.detail);
        console.log(error);
      });
  };

  const HandleShareEpisode = () => {
    if (auth.user.subscription_status !== "active") {
      setShowSubscribeModal(true);
    } else {
      axiosPrivate
        .post(`/podcast/episode/${props.episode.id}/auth-link`)
        .then((response) => {
          const shareLink = response.data.key;
          const shareLinkUrl = `https://podscribe.io/content/podcasts/${props.episode.feedId}/episode/${props.episode.id}?auth=${shareLink}`;
          setShareUrl(shareLinkUrl);
          setShowShareModal(true);
        })
        .catch((error) => {
          console.error("Error generating share link:", error);
          toast.error("Failed to generate share link. Please try again.");
        });
    }
  };

  const HandleCopyToClipboard = () => {
    copy(shareUrl);
    setShowShareModal(false);
  };

  const shareBodyText = (
    <>
      <p>Here's your shareable link that expires in 7 days:</p>
      <a href={shareUrl} className="text-primary-500 hover:text-primary-600">
        {shareUrl}
      </a>
      <p className="mt-4 text-sm text-gray-500">
        By sharing, you're granting access to the public (summary) view of the episode notes.
      </p>
    </>
  );

  return (
    <section
      aria-labelledby="message-heading"
      className="min-w-0 flex-1 lg:h-screen flex flex-col overflow-y-auto lg:order-last">
      {/* Top section */}
      <div className="flex-shrink-0 bg-white border-b border-neutral-200">
        {/* Toolbar*/}

        <div className="h-16 flex flex-col justify-center">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="py-3 flex justify-between">
              {/* Left buttons */}
              <div className="">
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                  <button
                    type="button"
                    className="lg:hidden mr-5 relative inline-flex items-center px-2 py-2 rounded-md border border-neutral-300 bg-white text-sm font-medium text-neutral-500 hover:bg-neutral-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-support-yellow-600 focus:border-support-yellow-600"
                    onClick={() => handleInboxSidebar()}>
                    <span className="sr-only">Open Mobile sidebar</span>
                    <MenuAlt1Icon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <div className="inline-flex rounded-md shadow-sm">
                    <button
                      type="button"
                      onClick={ShowPodscribeModal}
                      disabled={props.episode.is_summarized}
                      className={`relative inline-flex items-center sm:px-4 px-2 py-1 rounded-l-md border border-neutral-300 bg-white text-sm font-medium ${
                        props.episode.is_summarized ? "text-neutral-400" : "text-primary-600"
                      } hover:bg-neutral-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-secondary-400 focus:border-secondary-400`}>
                      <LightningBoltIcon className="h-5 w-5" aria-hidden="true" />
                      <span className="hidden sm:block ml-2">Podscribe</span>
                    </button>
                    <Menu as="div" className="relative -ml-px block">
                      <Menu.Button className="relative inline-flex items-center sm:px-4 px-2 py-2 rounded-r-md border border-neutral-300 bg-white text-sm font-medium text-primary-500 hover:bg-neutral-50">
                        <span className="sr-only">Open options</span>
                        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  type="button"
                                  title="Share"
                                  onClick={HandleShareEpisode}
                                  className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "relative inline-flex items-center px-2 py-1 text-sm font-medium text-neutral-500 w-full"
                                  )}>
                                  <ShareIcon className="h-5 w-5 mr-2 fill-primary-500" aria-hidden="true" />
                                  Share
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  type="button"
                                  title="Transcribe"
                                  onClick={HandleUpvoteEpisode}
                                  className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "relative inline-flex items-center px-2 py-1 text-sm font-medium text-neutral-500 w-full"
                                  )}>
                                  <ThumbUpIcon className="h-5 w-5 mr-2 fill-primary-500" aria-hidden="true" />
                                  Like
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  type="button"
                                  onClick={() => props.RemoveEpisodeHandler(props.episode.feedId, props.episode.id)}
                                  className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "relative inline-flex items-center px-2 py-1 text-sm font-medium text-neutral-500 w-full"
                                  )}>
                                  <TrashIcon className="h-5 w-5 mr-2 text-rose-800" aria-hidden="true" />
                                  Remove
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </span>
              </div>

              {/* Right buttons */}
              <nav aria-label="Pagination">
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                  <button
                    onClick={props.handleOpenSearchModal}
                    className="relative inline-flex items-center sm:px-4 px-2 py-2 rounded-l-md border border-neutral-300 bg-white text-sm font-medium text-neutral-500 hover:bg-neutral-50 focus:ring-1">
                    <span className="sr-only">Previous</span>
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    onClick={props.SelectPreviousEpisodeHandler}
                    className="-ml-px relative inline-flex items-center sm:px-4 px-2 py-2 border border-neutral-300 bg-white text-sm font-medium text-neutral-500 hover:bg-neutral-50  ">
                    <span className="sr-only">Previous</span>
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    onClick={props.SelectNextEpisodeHandler}
                    className="-ml-px relative inline-flex items-center sm:px-4 px-2 py-2 rounded-r-md border border-neutral-300 bg-white text-sm font-medium text-neutral-500 hover:bg-neutral-50  ">
                    <span className="sr-only">Next</span>
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </span>
              </nav>
            </div>
          </div>
        </div>
        {/* Message header */}
      </div>
      <div className="flex-1 overflow-auto">
        <div className="bg-white pt-5 shadow space-y-1 flex flex-col">
          <div className="flex-1 flex-row px-4 sm:flex sm:justify-between sm:items-baseline sm:px-6 lg:px-8">
            <div className="flex flex-row space-x-2">
              <div className="">
                <Img
                  src={props.episode.feedImage}
                  className="h-16 w-16 text-neutral-400 rounded-full object-cover"
                  aria-hidden="true"
                />
              </div>
              <div className="flex flex-col">
                <h1 id="message-heading" className="text-lg font-medium text-primary-700">
                  <a
                    href={props.episode.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline inline-flex items-center">
                    <span className="mr-2">{props.episode.title}</span>
                    <ExternalLinkIcon className="h-4 w-4 flex-shrink-0" aria-hidden="true" />
                  </a>
                </h1>
                <Link to={`/podcasts/${props.episode.feedId}/episodes`}>
                  <h1 id="message-heading" className="text-sm font-medium text-secondary-700">
                    {props.episode.podcast_title}
                  </h1>
                </Link>
              </div>
            </div>

            <div className="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:justify-start">
              <BeatLoader color={"#4C63B6"} loading={props.episode.is_processing} />
            </div>
          </div>
          <div className="flex-1 px-4 sm:flex sm:justify-between sm:items-baseline sm:px-6 lg:px-8">
            <div className="flex-1 flex flex-col">
              {toggleEpisodeDescription ? (
                <div className="flex-1 flex flex-row mb-4 min-w-0">
                  <article className="flex-1 prose px-4 prose-sm">
                    {remove_html_tags(description)}
                    {props.episode.description.length > 100 && (
                      <button className="text-primary-500 hover:underline" onClick={toggleDescription}>
                        {showFullDescription ? "Less" : "More"}
                      </button>
                    )}
                  </article>
                </div>
              ) : null}
            </div>
          </div>
          {/* Thread section*/}
        </div>
        <div
          className={`m-3 pt-3 relative ${
            isYouTube || props.episode.content_type === "youtube" ? "pb-6 h-20" : "h-16"
          } w-auto`}>
          <ReactPlayer
            ref={playerRef}
            url={props.episode.enclosureUrl}
            playing={playAudio}
            controls={true}
            width="100%"
            height={isYouTube || props.episode.content_type === "youtube" ? "100px" : "40px"}
            className="absolute"
            config={{
              youtube: {
                playerVars: {
                  autoplay: 0,
                  controls: 1,
                  modestbranding: 1,
                },
              },
              file: {
                forceAudio: !(isYouTube || props.episode.content_type === "youtube"),
                attributes: {
                  style: { width: "100%", height: "40px" },
                },
              },
            }}
          />
        </div>
        <div className="flex flex-row py-4 space-y-2 sm:px-6 sm:space-y-4 lg:px-8">
          {showTranscribe ? (
            <InboxContent
              data={props.data}
              SelectSegmentHandler={SelectSegmentHandler}
              onEditorChange={props.onEditorChange}
              editorData={props.EditorData}
              showEditor={showEditor}
              episode={props.episode}
              selectedEpisodeState={props.selectedEpisodeState}
              onNoteEditorChange={props.onNoteEditorChange}
              HandleTranscribeEpisode={ShowPodscribeModal}
            />
          ) : null}
        </div>
      </div>
      <PodscribeActionModal
        show={showPodscribeModal}
        HandleOnClose={HandleOnClosePodscribeModal}
        HandleOnAction={HandleTranscribeEpisode}
        ActionButtonText="Podscribe"
        CloseButtonText="Cancel"
        title="Process episode"
        availableMinutes={auth.user.podscribe_minutes + auth.user.podscribe_topup_minutes}
        requiredMinutes={Math.ceil(props.episode.duration / 60)}
        BodyText={
          <div className="space-y-4">
            <h4 className="font-semibold text-lg">AI Processing Confirmation</h4>
            <div className="space-y-2">
              <p className="text-sm text-gray-600">
                You're about to AI process this episode. Here's what you need to know:
              </p>
              <ul className="list-disc list-inside text-sm text-gray-600 space-y-1">
                <li>
                  Estimated cost:{" "}
                  <span className="font-semibold text-gray-800">{Math.ceil(props.episode.duration / 60)} minutes</span>
                </li>
                <li>
                  Your available balance:{" "}
                  <span className="font-semibold text-gray-800">
                    {auth.user.podscribe_minutes + auth.user.podscribe_topup_minutes} minutes
                  </span>
                  {auth.user.subscription_status === "active" ? " (subscription + top-up)" : ""}
                </li>
                <li>Actual processing time may vary based on episode length and system workload</li>
                <li>Typical completion time: A few minutes</li>
              </ul>
            </div>
            {auth.user.subscription_status !== "active" && (
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                      As a free user, you have a limit of 100 minutes. Once exhausted, you'll need to subscribe to
                      continue processing new content.
                    </p>
                  </div>
                </div>
              </div>
            )}
            <p className="font-medium">Are you sure you want to proceed with processing this episode?</p>
          </div>
        }
      />
      <ActionModalInfo
        show={showShareModal}
        title="Share Episode"
        BodyText={shareBodyText}
        CloseButtonText="Close"
        ActionButtonText="Copy to Clipboard"
        HandleOnClose={() => setShowShareModal(false)}
        HandleOnAction={HandleCopyToClipboard}
      />
      <ActionModalPleaseSubscribeToUse
        show={showSubscribeModal}
        HandleOnClose={() => setShowSubscribeModal(false)}
        title="Subscribe to Share"
        BodyText="To share episodes, you need an active subscription. Subscribe now to unlock this feature and many more!"
        CloseButtonText="Close"
      />
    </section>
  );
};

export default InboxMessage;
