import React from "react";
import { HeartIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { HeartIcon as HeartIconOutlined, ExternalLinkIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import Img from "./Img";

export default function PodcastCard({ feed, handleFavorite }) {
  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden transition-all duration-300 hover:shadow-lg mt-6 mb-8 sm:mb-6">
      <div className="p-4">
        <div className="flex flex-col sm:flex-row">
          <div className="flex-shrink-0 mb-4 sm:mb-0 sm:mr-4 flex justify-center">
            <Img
              src={feed.image}
              alt={feed.title}
              className="h-24 w-24 sm:h-32 sm:w-32 rounded-md object-cover shadow-sm"
            />
          </div>
          <div className="flex-grow">
            <div className="flex flex-col sm:flex-row justify-between items-start">
              <Link to={`/podcasts/${feed.id}/episodes`} className="group mb-2 sm:mb-0">
                <h4 className="text-lg font-bold text-primary-600 group-hover:text-secondary-600 transition-colors duration-200 flex items-center">
                  {feed.title.length > 50 ? feed.title.substring(0, 50) + "..." : feed.title}
                  <ChevronRightIcon className="ml-1 h-5 w-5" aria-hidden="true" />
                </h4>
              </Link>
              {feed.is_added_to_library && feed.episode_id ? (
                <Link
                  to={`/library/${feed.episode_id}`}
                  className="sm:ml-2 transition-all duration-200 px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 active:scale-95 flex items-center bg-green-100"
                >
                  <svg className="h-5 w-5 text-green-600 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="text-sm font-medium">View in Library</span>
                </Link>
              ) : (
                <button
                  onClick={() => handleFavorite(feed.id, !feed.is_favorite)}
                  className={`sm:ml-2 transition-all duration-200 px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 active:scale-95 flex items-center ${
                    feed.is_favorite ? "bg-red-100 hover:bg-red-200" : "bg-primary-100 hover:bg-primary-200"
                  }`}
                  aria-label={feed.is_favorite ? "Remove from favorites" : "Add to favorites"}
                >
                  {feed.is_favorite ? (
                    <HeartIcon className="h-5 w-5 text-red-600 mr-2" aria-hidden="true" />
                  ) : (
                    <HeartIconOutlined className="h-5 w-5 text-primary-600 mr-2" aria-hidden="true" />
                  )}
                  <span className="text-sm font-medium">{feed.is_favorite ? "Remove" : "Add"}</span>
                </button>
              )}
            </div>
            <h4 className="text-sm font-medium text-neutral-600">
              by <span className="text-secondary-600">{feed.author}</span>
            </h4>
            <div className="flex flex-wrap mt-2 gap-1">
              {feed.categories &&
                Object.values(feed.categories).map((category, i) => (
                  <span
                    key={i}
                    className="inline-flex items-center rounded-full bg-primary-50 px-2.5 py-0.5 text-xs font-medium text-primary-700 ring-1 ring-inset ring-primary-700/10"
                  >
                    {category}
                  </span>
                ))}
            </div>
          </div>
        </div>
        <p className="mt-3 text-sm text-neutral-600 line-clamp-3">{feed.description}</p>
        <div className="mt-4 flex justify-between items-center">
          <Link
            to={feed.link}
            target="_blank"
            className="text-primary-600 hover:text-secondary-600 text-sm font-medium flex items-center"
          >
            Visit website
            <ExternalLinkIcon className="ml-1 h-4 w-4" aria-hidden="true" />
          </Link>
        </div>
      </div>
    </div>
  );
}
