import { createContext, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { axiosPrivate } from "../common/axiosPrivate";
import { toast } from "react-hot-toast";

const LibraryDataContext = createContext({
  podcasts: [],
  episodes: [],
  notes: [],
  loading: false,
  error: null,
});

export const LibraryDataProvider = ({ children }) => {
  const { auth } = useAuth();
  const [LibraryData, setLibraryData] = useState({
    podcasts: [],
    episodes: [],
    notes: [],
    loading: true,
    error: null,
  });

  const startEpisodePolling = (
    feedId,
    episodeId,
    interval = 5000,
    duration = 60000,
  ) => {
    const pollId = setInterval(async () => {
      const updatedEpisode = await refreshLibraryEpisode(feedId, episodeId);
      if (!updatedEpisode.is_processing) {
        clearInterval(pollId);
      }
    }, interval);

    // Cancel polling after the specified duration
    setTimeout(() => {
      clearInterval(pollId);
    }, duration);

    return pollId;
  };

  const refreshLibrary = async () => {
    setLibraryData((prevData) => ({ ...prevData, loading: true }));
    try {
      const response = await axiosPrivate.get("/library");
      setLibraryData({
        episodes: response.data,
        loading: false,
        error: null,
      });
    } catch (error) {
      console.log(error);
    }
  };
  // fetch the data from the backend
  useEffect(() => {
    if (!auth.token) {
      return;
    }
    const fetchLibraryData = async () => {
      setLibraryData((prevData) => ({ ...prevData, loading: true }));
      try {
        const response = await axiosPrivate.get("library/");
        console.log("Fetched Library Data: ", response.data);
        setLibraryData({
          episodes: response.data,
          loading: false,
          error: null,
        });
      } catch (error) {
        console.log(error);
        setLibraryData((prevData) => ({
          ...prevData,
          loading: false,
          error: error,
        }));
      }
    };

    fetchLibraryData();
  }, [auth.token]);

  const refreshLibraryEpisode = async (feedId, episodeId) => {
    try {
      const response = await axiosPrivate.get(
        `/library/podcast/${feedId}/${episodeId}`,
      );
      const updatedEpisode = response.data;
      setLibraryData((prevData) => ({
        ...prevData,
        episodes: prevData.episodes.map((episode) =>
          episode.id === episodeId ? updatedEpisode : episode,
        ),
        loading: false,
        error: null,
      }));
      return updatedEpisode;
    } catch (error) {
      console.log(error);
      setLibraryData((prevData) => ({ ...prevData, loading: false }));
      return null;
    }
  };

  const addLibraryEpisode = async (feedId, episodeId) => {
    try {
      console.log("Adding episode to library: ", feedId, episodeId);
      const response = await axiosPrivate.post(
        `/library/${feedId}/${episodeId}`,
      );
      console.log(response.data);
      setLibraryData((prevData) => ({
        ...prevData,
        episodes: [...prevData.episodes, response.data],
      }));
      toast.success("Episode added to library");
    } catch (error) {
      // if the error is because the episode is already in the library, just refresh the episode
      if (error.response && error.response.status === 409) {
        refreshLibraryEpisode(feedId, episodeId);
        toast.success("Episode already in library");
        return;
      }

      console.log(error);
      toast.error("Error communicating with the server");
    }
  };

  // add removeLibraryEpisode function
  const removeLibraryEpisode = async (feedId, episodeId) => {
    try {
      await axiosPrivate.delete(`/library/${feedId}/${episodeId}`);
      setLibraryData((prevData) => ({
        ...prevData,
        episodes: prevData.episodes.filter(
          (episode) => episode.id !== episodeId,
        ),
      }));
      toast.success("Episode removed from library");
    } catch (error) {
      console.log(error);
      toast.error("Error communicating with the server");
    }
  };

  return (
    <LibraryDataContext.Provider
      value={{
        LibraryData,
        setLibraryData,
        refreshLibraryEpisode,
        addLibraryEpisode,
        refreshLibrary,
        removeLibraryEpisode,
        startEpisodePolling,
      }}
    >
      {children}
    </LibraryDataContext.Provider>
  );
};

export default LibraryDataContext;
