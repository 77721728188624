import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosPrivate } from "../common/axiosPrivate";
import SEO from "./SEO";
import CardHeadingChildButtons from "../UI/CardHeadingChildButtons";
import { BarLoader } from "react-spinners";
import PodcastEpisodeCard from "../UI/PodcastEpisodeCard";
import SearchInput from "../UI/SearchComponent";
import Button from "../UI/Button";
import { PlusIcon } from "@heroicons/react/outline";
import useLibraryData from "../hooks/useLibraryData";
import { useDebounceValue } from "usehooks-ts";
import ActionModalPleaseSubscribeToUse from "../UI/ActionModalPleaseSubscribeToUse";
import useAuth from "../hooks/useAuth";

export default function UserUploads() {
  const [isLoading, setIsLoading] = useState(true);
  const [episodes, setEpisodes] = useState([]);
  const [filteredEpisodes, setFilteredEpisodes] = useState([]);
  const [filterTerm, setFilterTerm] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceValue("", 300);
  const [displayCount, setDisplayCount] = useState(20);
  const { LibraryData, addLibraryEpisode, removeLibraryEpisode } = useLibraryData();
  const [showModal, setShowModal] = useState(false);
  const { auth } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosPrivate.get("/custom/episodes");
        setEpisodes(response.data.episodes);
        setFilteredEpisodes(response.data.episodes.slice(0, displayCount));
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleAddRemove = (feedId, episodeId, isAdded) => {
    if (auth.user.subscription_status == null && LibraryData.episodes.length >= 10 && isAdded) {
      setShowModal(true);
      return;
    }
    if (isAdded) {
      addLibraryEpisode(feedId, episodeId);
    } else {
      removeLibraryEpisode(feedId, episodeId);
    }
    setEpisodes(episodes.map(episode => 
      episode.id === episodeId ? { ...episode, is_added_to_library: isAdded } : episode
    ));
    setFilteredEpisodes(filteredEpisodes.map(episode => 
      episode.id === episodeId ? { ...episode, is_added_to_library: isAdded } : episode
    ));
  };

  const handleSearch = (e) => {
    setDebouncedSearchValue(e.target.value);
    setFilterTerm(e.target.value);
  };

  useEffect(() => {
    const filtered = episodes.filter(episode => 
      episode.title.toLowerCase().includes(debouncedSearchValue.toLowerCase())
    );
    setFilteredEpisodes(filtered.slice(0, displayCount));
  }, [debouncedSearchValue, episodes, displayCount]);

  const handleShowMore = () => {
    setDisplayCount(prevCount => prevCount + 5);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <SEO title="PodScribe.IO | User Uploads" />
      <div className="flex">
        <section className="min-w-0 flex-1 lg:h-screen flex flex-col overflow-y-auto lg:order-last">
          <BarLoader width={"100%"} color={"#fca311"} loading={isLoading} size={150} />
          <CardHeadingChildButtons title="User Uploads" subHeading="View all your uploaded content">
            <div className="flex flex-row space-x-3">
              <SearchInput
                value={filterTerm}
                onChange={handleSearch}
                className="rounded-r-md"
              />
            </div>
          </CardHeadingChildButtons>
          <div className="flex flex-col flex-1 lg:overflow-y-auto items-center">
            <div className="h-screen h-screen-ios w-screen md:w-8/12 lg:w-9/12 px-2 py-2">
              {!isLoading && filteredEpisodes.length === 0 && (
                <div className="text-center mt-4">
                  <h3 className="text-lg text-neutral-400">
                    No uploads found. Start uploading your content!
                  </h3>
                </div>
              )}
              <div className="mt-4 mx-3">
                {!isLoading &&
                  filteredEpisodes.map((episode, index) => (
                    <PodcastEpisodeCard key={index} episode={episode} handleAddRemove={handleAddRemove} />
                  ))}
              </div>
              <div className="mt-4 mx-3 pb-12">
                {!filterTerm && !isLoading && displayCount < episodes.length && (
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                      <Button type="button" onClick={handleShowMore} className="py-1 gap-x-1">
                        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Load More
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <ActionModalPleaseSubscribeToUse
          show={showModal}
          HandleOnClose={handleCloseModal}
          CloseButtonText="Cancel"
          title="Add Episode to Library"
          BodyText="You have reached the maximum (10) number of episodes in your library. Subscribe to add more episodes."
          button_text="Back to login"
        />
      </div>
    </>
  );
}
