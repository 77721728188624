import React from "react";
import Img from "../../UI/Img";
import { Container } from "./Container";
import backgroundImage from "../../assets/background-faqs.jpg"; // You might want to use a different background image

const PartnerBanners = () => {
  return (
    <div className="flex justify-center">
      <a
        href="https://theresanaiforthat.com/ai/podscribe-1721038783/?ref=featured&v=2464322"
        target="_blank"
        rel="nofollow noopener noreferrer"
        className="inline-block">
        <img
          width="250"
          src="https://media.theresanaiforthat.com/featured-on-taaft.png?width=250"
          alt="Featured on There's an AI for That"
          className="max-w-full h-auto"
        />
      </a>
    </div>
  );
};

export default PartnerBanners;
