import { Fragment, useRef, useState, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, DocumentIcon, InformationCircleIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import LinkInput from "./LinkInput";
import FileDropzone from "./FileDropzone";
import { axiosPrivate } from "../../common/axiosPrivate";
import useLibraryData from "../../hooks/useLibraryData";
import { toast } from "react-toastify";

export default function AddContentModal({ show, onClose }) {
  const [activeTab, setActiveTab] = useState("link");
  const [link, setLink] = useState("");
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const cancelButtonRef = useRef(null);
  const { setLibraryData } = useLibraryData();

  const resetState = useCallback(() => {
    setActiveTab("link");
    setLink("");
    setFile(null);
    setTitle("");
    setDescription("");
    setIsLoading(false);
    setError("");
  }, []);

  const handleClose = useCallback(() => {
    resetState();
    onClose();
  }, [onClose, resetState]);

  const [youtubeLink, setYoutubeLink] = useState("");
  const [youtubeFile, setYoutubeFile] = useState(null);
  const [youtubeData, setYoutubeData] = useState(null);
  const [isLoadingYouTubeData, setIsLoadingYouTubeData] = useState(false);

  const parseYouTubeId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  const fetchYouTubeData = async (videoId) => {
    setIsLoadingYouTubeData(true);
    setYoutubeData(null);
    try {
      const response = await axiosPrivate.get(`/custom/youtube-data/${videoId}`);
      const data = response.data;
      console.log("Response:", data);
      setYoutubeData({
        title: data.title,
        description: data.description,
        thumbnail: data.thumbnail,
      });
    } catch (error) {
      console.error("Error fetching YouTube data:", error);
      toast.error("Failed to fetch YouTube video data. Please check the link and try again.");
      setYoutubeData(null);
    } finally {
      setIsLoadingYouTubeData(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setError("");

    try {
      let response;
      const formData = new FormData();

      if (activeTab === "youtube") {
        if (!youtubeFile || !youtubeLink || !youtubeData) {
          throw new Error("Please provide a YouTube link, upload an audio file, and ensure video data is fetched.");
        }
        const youtubeId = parseYouTubeId(youtubeLink);
        if (!youtubeId) {
          throw new Error("Invalid YouTube link");
        }
        formData.append("file", youtubeFile);
        formData.append("youtube_id", youtubeId);
        formData.append("title", youtubeData.title);
        formData.append("description", youtubeData.description);
        formData.append("youtube_thumbnail", youtubeData.thumbnail);
        formData.append("youtube_duration", Math.round(youtubeData.duration || 0));
        formData.append("content_type", "youtube");
      } else if (activeTab === "link") {
        formData.append("link", link);
        formData.append("content_type", "custom");
        formData.append("title", title);
        formData.append("description", description);
      } else {
        formData.append("file", file);
        formData.append("content_type", "custom");
        formData.append("title", title);
        formData.append("description", description);
      }

      response = await axiosPrivate.post("/custom/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setLibraryData((prevData) => ({
        ...prevData,
        episodes: [...prevData.episodes, response.data],
      }));
      toast.success("Content added successfully!");
      handleClose();
    } catch (err) {
      console.error("Error adding content:", err);
      setError(err.message || "Failed to upload content. Please try again.");
      toast.error(err.message || "Failed to upload content. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-neutral-400 hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    onClick={handleClose}>
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-neutral-900">
                      Add Content to Inbox
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-neutral-500">
                        Add content to your inbox by pasting a link, uploading an audio file.
                      </p>
                      <p className="text-sm text-neutral-500">
                        You can also add content to your inbox by searching for Podcasts in the{" "}
                        <Link to="/discover" className="text-primary-600 hover:text-primary-500">
                          Discovery
                        </Link>
                        .
                      </p>
                    </div>
                    <div className="mt-4">
                      <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">
                          Select a tab
                        </label>
                        <select
                          id="tabs"
                          name="tabs"
                          className="block w-full rounded-md border-neutral-300 focus:border-primary-500 focus:ring-primary-500"
                          value={activeTab}
                          onChange={(e) => setActiveTab(e.target.value)}>
                          <option value="link">Link</option>
                          <option value="file">File Upload</option>
                          <option value="youtube">YouTube Link</option>
                        </select>
                      </div>
                      <div className="hidden sm:block">
                        <nav className="flex space-x-4" aria-label="Tabs">
                          {[
                            { id: "link", label: "Link" },
                            { id: "file", label: "File" },
                            { id: "youtube", label: "Import YouTube" },
                          ].map((tab) => (
                            <button
                              key={tab.id}
                              onClick={() => setActiveTab(tab.id)}
                              className={`${
                                activeTab === tab.id
                                  ? "bg-primary-100 text-primary-700"
                                  : "text-neutral-500 hover:text-neutral-700"
                              } px-3 py-2 font-medium text-sm rounded-md`}>
                              {tab.label}
                            </button>
                          ))}
                        </nav>
                      </div>
                    </div>
                    <div className="mt-4">
                      {activeTab === "link" && (
                        <div>
                          <LinkInput link={link} setLink={setLink} />
                          <div className="mt-4">
                            <label htmlFor="link-title" className="block text-sm font-medium text-neutral-700">
                              Title
                            </label>
                            <input
                              type="text"
                              name="link-title"
                              id="link-title"
                              className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              required
                            />
                          </div>
                          <div className="mt-4">
                            <label htmlFor="link-description" className="block text-sm font-medium text-neutral-700">
                              Description
                            </label>
                            <textarea
                              name="link-description"
                              id="link-description"
                              rows={3}
                              className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                      )}
                      {activeTab === "file" && (
                        <div>
                          <FileDropzone file={file} setFile={setFile} maxSize={300 * 1024 * 1024} />
                          {file && (
                            <div className="mt-2 flex items-center text-sm text-neutral-500">
                              <DocumentIcon className="mr-1 h-5 w-5 text-neutral-400" />
                              <span>{file.name}</span>
                            </div>
                          )}
                          <div className="mt-4">
                            <label htmlFor="file-title" className="block text-sm font-medium text-neutral-700">
                              Title
                            </label>
                            <input
                              type="text"
                              name="file-title"
                              id="file-title"
                              className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              required
                            />
                          </div>
                          <div className="mt-4">
                            <label htmlFor="file-description" className="block text-sm font-medium text-neutral-700">
                              Description
                            </label>
                            <textarea
                              name="file-description"
                              id="file-description"
                              rows={3}
                              className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                      )}
                      {activeTab === "youtube" && (
                        <div>
                          <p className="mb-4 text-sm text-neutral-700">
                            <strong>Important:</strong> Please respect YouTube's Terms of Service. Only import videos
                            that you have created or have explicit permission to use.
                          </p>
                          <label htmlFor="youtube-link" className="block text-sm font-medium text-neutral-700">
                            YouTube Link
                          </label>
                          <input
                            type="text"
                            name="youtube-link"
                            id="youtube-link"
                            className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                            placeholder="https://www.youtube.com/watch?v=..."
                            value={youtubeLink}
                            onChange={(e) => {
                              setYoutubeLink(e.target.value);
                              setYoutubeData(null);
                              const videoId = parseYouTubeId(e.target.value);
                              if (videoId) {
                                fetchYouTubeData(videoId);
                              }
                            }}
                          />
                          {isLoadingYouTubeData && (
                            <p className="mt-2 text-sm text-neutral-500">Loading YouTube data...</p>
                          )}
                          {youtubeLink && !parseYouTubeId(youtubeLink) && (
                            <p className="mt-2 text-sm text-red-600">Invalid YouTube link</p>
                          )}
                          <div className="mt-4">
                            <FileDropzone file={youtubeFile} setFile={setYoutubeFile} maxSize={300 * 1024 * 1024} />
                            {youtubeFile && (
                              <div className="mt-2 flex items-center text-sm text-neutral-500">
                                <DocumentIcon className="mr-1 h-5 w-5 text-neutral-400" />
                                <span>{youtubeFile.name}</span>
                              </div>
                            )}
                          </div>
                          {youtubeData && (
                            <div className="mt-4">
                              <h4 className="text-sm font-medium text-neutral-700">Video Information:</h4>
                              <div className="flex items-start mt-2">
                                <img
                                  src={youtubeData.thumbnail}
                                  alt="Video thumbnail"
                                  className="w-32 h-auto mr-4 rounded"
                                />
                                <div>
                                  <p className="text-sm text-neutral-700 font-medium">{youtubeData.title}</p>
                                  <p className="text-sm text-neutral-500 mt-1">
                                    {youtubeData.description.slice(0, 100)}
                                    {youtubeData.description.length > 100 ? "..." : ""}
                                  </p>
                                </div>
                              </div>
                              <div className="mt-4">
                                <h4 className="text-sm font-medium text-neutral-700">
                                  Download Audio (for content you have rights to):
                                </h4>
                                <p className="text-sm text-neutral-500 mt-1">
                                  You can use{" "}
                                  <a
                                    href="https://github.com/yt-dlp/yt-dlp"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-primary-600 hover:text-primary-500">
                                    yt-dlp
                                  </a>{" "}
                                  to download the audio if you have the right to do so.
                                </p>
                                <div className="mt-2 bg-neutral-100 p-2 rounded-md">
                                  <p className="text-sm font-mono break-all">
                                    yt-dlp -x --audio-format mp3 -o "%(id)s.%(ext)s" {youtubeLink}
                                  </p>
                                </div>
                                <p className="text-xs text-neutral-500 mt-1">
                                  Note: Ensure you have the necessary rights before downloading any content.
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 sm:ml-3 sm:w-auto disabled:bg-neutral-300 disabled:cursor-not-allowed"
                    onClick={handleSubmit}
                    disabled={
                      isLoading ||
                      (activeTab === "link" && (!link || !title || !description)) ||
                      (activeTab === "file" && (!file || !title || !description)) ||
                      (activeTab === "youtube" && (!youtubeLink || !youtubeFile || !youtubeData))
                    }>
                    {isLoading ? "Adding..." : "Add Content"}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 hover:bg-neutral-50 sm:mt-0 sm:w-auto"
                    onClick={handleClose}
                    ref={cancelButtonRef}>
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
