/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          rose: colors.rose,
        },
      },
    },
  }
  ```
*/
import { useState, useEffect } from "react";
import InfoMsgAction from "../../UI/InfoMsgAction";
import CardHeadingSearch from "../../UI/CardHeadingSearch";
import useAuth from "../../hooks/useAuth";
import Editor from "./Editor";
import Transcript from "./Transcript";
import { axiosPrivate } from "../../common/axiosPrivate";
import React from "react";
import Summary from "./Summary";
import { formatDistanceToNow, differenceInSeconds } from "date-fns";
import Button from "../../UI/Button";
import { useDebounceValue } from "usehooks-ts";
import MindMap from "./MindMap";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function InboxContent(props) {
  const { auth } = useAuth();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [EditorSaveContent, setEditorSaveContent] = useState(null);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState("");
  const [loading, setloading] = useState(true);
  // Tab state
  const [selectedTab, setSelectedTab] = useState("Summary");
  // SearchTerm state
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceValue("", 300);
  const [lastSaved, setLastSaved] = useState(null);
  const [saveNow, setSaveNow] = useState(false);

  // Save notes to API
  const handleEditorSave = (content) => {
    console.log("handleEditorSave", content);
    // record last saved time
    setLastSaved(new Date());
    setEditorSaveContent(content);
    setSaveNow(false);
  };

  const handleOnDemandSave = () => {
    setSaveNow(true);
  };

  useEffect(() => {
    setLastSaved(null);
  }, [props.selectedEpisodeState]);

  useEffect(() => {
    if (EditorSaveContent) {
      props.selectedEpisodeState.episode.note = EditorSaveContent.data;
      console.log("EditorSaveContent", EditorSaveContent);
      // send notes to backend using axios
      let method = "post";
      let url = `/users/notes`;
      let body = {
        data: EditorSaveContent.data,
        episode_id: EditorSaveContent.episode_id,
      };

      axiosPrivate
        .post(url, body)
        .then((res) => {
          setResponse(res.data);
          console.log(`Note saved successfully for ${EditorSaveContent.title}!`);
          console.log("Response", res.data);
        })
        .catch((err) => {
          setError(err);
          console.log("Error", err);
        })
        .finally(() => {
          //setloading(false);
        });
    }
  }, [EditorSaveContent]);

  const onSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    setDebouncedSearchValue(searchTerm);
  };
  // Handle tab selection (onSelect)
  const onSelect = (selectedTab) => {
    console.log("selectedTab", selectedTab);
    setSelectedTab(selectedTab);
    if (selectedTab === "Transcript") {
      setShowSearchBar(true);
    } else {
      setShowSearchBar(false);
    }
  };

  return (
    <>
      <div className="flex-1 flex flex-col">
        <CardHeadingSearch title="Transcript" onSearch={onSearch} onSelect={onSelect} showSearchBar={showSearchBar} />
        {/* Summary, Transcript and Notes area */}
        {selectedTab === "Summary" ? (
          <>
            {props.selectedEpisodeState.episode.is_summarized ? (
              <div className="flex-1 flex flex-col items-center justify-between border border-grey-500 rounded-lg shadow-lg m-5 overflow-y-auto">
                <Summary
                  selectedEpisodeState={props.selectedEpisodeState}
                  SelectSegmentHandler={props.SelectSegmentHandler}
                />
              </div>
            ) : (
              <InfoMsgAction
                msg="Podcast summary is not available"
                action="Transcribe"
                onClick={props.HandleTranscribeEpisode}
              />
            )}
          </>
        ) : selectedTab === "MindMap" ? (
          <>
            {props.selectedEpisodeState.episode.is_summarized ? (
              <MindMap selectedEpisodeState={props.selectedEpisodeState} />
            ) : (
              <InfoMsgAction
                msg="Podcast summary is not available"
                action="Transcribe"
                onClick={props.HandleTranscribeEpisode}
              />
            )}
          </>
        ) : selectedTab === "Transcript" ? (
          <>
            {props.selectedEpisodeState.episode.transcription ? (
              <div className="flex-1 border border-grey-500 rounded-lg shadow-lg m-5 overflow-y-auto">
                <Transcript
                  data={props.selectedEpisodeState.episode.transcription.paragraphs.paragraphs}
                  searchTerm={debouncedSearchValue}
                  SelectSegmentHandler={props.SelectSegmentHandler}
                />
              </div>
            ) : (
              <InfoMsgAction
                msg="Podcast not yet transcribed!"
                action="Transcribe"
                onClick={props.HandleTranscribeEpisode}
              />
            )}
          </>
        ) : selectedTab === "Notes" ? (
          <div className="flex-1 rounded-lg shadow-lg p-3 m-5 overflow-y-auto text-primary-600">
            <Editor data={props.selectedEpisodeState} onChange={handleEditorSave} saveNow={saveNow} />
            <div className="flex flex-row">
              <Button className="bg-primary-500 hover:bg-primary-700 text-primary" onClick={handleOnDemandSave}>
                Save
              </Button>
              {lastSaved && (
                <div className="text-sm text-secondary-500 px-5">
                  Last saved{" "}
                  {differenceInSeconds(new Date(), lastSaved) < 10 ? "now" : `${formatDistanceToNow(lastSaved)} ago`}
                </div>
              )}
            </div>
          </div>
        ) : null}
        {/* End of Summary, Transcript and Notes area */}
      </div>
    </>
  );
}
