import React, { useRef, useState } from "react";
import CardHeadingChildButtons from "../UI/CardHeadingChildButtons";
import { SearchIcon, XIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import SEO from "./SEO";

export default function Discover() {
  const searchInputRef = useRef(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchOnClick = () => {
    const term = searchInputRef.current.value;
    console.log("Search term: ", term);
    // redirect to search results page
    navigate(`/discover/results/podcast/${term}`);
  };

  const handleInputOnChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearchOnClick = () => {
    searchInputRef.current.value = "";
    setSearchTerm("");
  };

  return (
    <>
      <SEO title="PodScribe.IO | Discover" />
      <div className="flex">
        <section
          aria-labelledby="message-heading"
          className="min-w-0 flex-1 lg:h-screen flex flex-col overflow-y-auto lg:order-last h-screen-ios h-screen">
          <CardHeadingChildButtons title="Discover" subHeading="Discover new podcasts" />
          <div className="flex flex-col flex-1 lg:overflow-y-auto items-center mt-24 lg:mt-36">
            <div className="text-center">
              <div className="flex flex-col items-center">
                <div className="flex flex-row w-full max-w-md">
                  <div className="w-full">
                    <div className="relative mt-2 rounded-md shadow-sm w-full">
                      <input
                        type="text"
                        ref={searchInputRef}
                        name="search"
                        id="search"
                        title="Press Enter to search"
                        onChange={handleInputOnChange}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            handleSearchOnClick();
                          }
                        }}
                        className="block w-full rounded-l-lg border-0 py-3 pl-8 lg:pl-8 lg:pr-8 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Search podcasts"
                      />
                      <div className="absolute inset-y-0 right-1 flex items-center pl-3">
                        {searchTerm.length > 0 && (
                          <button onClick={handleClearSearchOnClick} className="">
                            <span className="sr-only">Clear search</span>
                            <XIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={handleSearchOnClick}
                    className="inline-flex mt-2 py-3 px-3 items-center border border-transparent text-sm font-medium rounded-r-md shadow-sm text-white bg-primary-400 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                    <SearchIcon className="h-5 w-5 text-white" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
