import { useEffect, useState } from "react";
import Img from "../../UI/Img";
import { Tab } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

import classNames from "classnames";

import { Container } from "./Container";
import backgroundImage from "../../assets/stacked-waves-haikei.svg";
import intro from "../../assets/screenshots/intro.png";
import mindmap from "../../assets/screenshots/mindmap.png";
import search from "../../assets/screenshots/podcast-search.png";
import transcript from "../../assets/screenshots/transcript.png";
import chapters from "../../assets/screenshots/chapters.png";
import add_content from "../../assets/screenshots/add_content.png";
const features = [
  {
    title: "Get Actionable Knowledge",
    description:
      "Unlock the power of podcasts with our AI-generated summaries, precise timestamped chapters, and insightful takeaways. Transform lengthy episodes into easily digestible, actionable knowledge that you can apply immediately.",
    image: mindmap,
  },
  {
    title: "Search for Podcast Episodes",
    description: "Discover your next favorite podcast with our intelligent search engine. Easily find and add relevant episodes to your personal library, creating a curated collection of knowledge tailored to your interests.",
    image: search,
  },
  {
    title: "Bring Your Own Content",
    description: "Expand your learning beyond podcasts. Import your own audio content or YouTube videos and watch as our AI transforms them into rich, interactive transcripts complete with summaries and key insights.",
    image: add_content,
  },
  {
    title: "Flexible, Usage-Based Pricing",
    description: "Enjoy cost-effective access to a world of knowledge. Benefit from our community-driven model where you can access episodes processed by others, and only pay for the minutes you use with our flexible subscription plans.",
    image: transcript,
  },
];

export function PrimaryFeatures() {
  let [tabOrientation, setTabOrientation] = useState("horizontal");
  let [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let lgMediaQuery = window.matchMedia("(min-width: 1024px)");

    function onMediaQueryChange({ matches }) {
      setTabOrientation(matches ? "vertical" : "horizontal");
    }

    onMediaQueryChange(lgMediaQuery);
    lgMediaQuery.addEventListener("change", onMediaQueryChange);

    return () => {
      lgMediaQuery.removeEventListener("change", onMediaQueryChange);
    };
  }, []);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : features.length - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < features.length - 1 ? prevIndex + 1 : 0));
  };

  return (
    <section
      id="features"
      aria-label="Features for running your books"
      className="relative overflow-hidden bg-primary-400 pb-28 pt-20 sm:py-32">
      <div className="absolute inset-0 bg-black opacity-10 z-10"></div>
      <Img
        className="absolute left-1/2 top-1/2 max-w-none translate-x-[-44%] translate-y-[-42%] z-0"
        src={backgroundImage}
        alt=""
        width={2245}
        height={1636}
      />
      <Container className="relative z-20">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
            Unleash the Power of Podcasts with AI-Driven Insights
          </h2>
          <p className="mt-6 text-lg tracking-tight text-primary-100">
            PodScribe.IO revolutionizes your podcast experience by transforming audio content into accessible,
            actionable, and collaborative knowledge. Discover, learn, and contribute to a thriving community of creators
            and learners.
          </p>
        </div>
        <Tab.Group
          as="div"
          className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
          vertical={tabOrientation === "vertical"}
          selectedIndex={currentIndex}
          onChange={setCurrentIndex}>
          {({ selectedIndex }) => (
            <>
              {tabOrientation === "horizontal" && (
                <div className="relative z-10 lg:hidden">
                  <div className="absolute inset-0 flex items-center justify-between px-4">
                    <button
                      onClick={goToPrevious}
                      className="p-2 rounded-full bg-white/10 text-white hover:bg-white/20">
                      <ChevronLeftIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <button onClick={goToNext} className="p-2 rounded-full bg-white/10 text-white hover:bg-white/20">
                      <ChevronRightIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="flex justify-center items-center py-4">
                    <h3 className="text-white text-lg font-semibold">{features[selectedIndex].title}</h3>
                  </div>
                </div>
              )}
              <div
                className={classNames(
                  "-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5",
                  tabOrientation === "horizontal" ? "hidden lg:flex" : ""
                )}>
                <Tab.List className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                  {features.map((feature, featureIndex) => (
                    <div
                      key={feature.title}
                      className={classNames(
                        "group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6",
                        selectedIndex === featureIndex
                          ? "bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10"
                          : "hover:bg-white/10 lg:hover:bg-white/5"
                      )}>
                      <h3>
                        <Tab
                          className={classNames(
                            "font-display text-lg focus:outline-none",
                            selectedIndex === featureIndex
                              ? "text-primary-600 lg:text-white"
                              : "text-primary-100 hover:text-white lg:text-white"
                          )}>
                          <span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none" />
                          {feature.title}
                        </Tab>
                      </h3>
                      <p
                        className={classNames(
                          "mt-2 hidden text-sm lg:block",
                          selectedIndex === featureIndex ? "text-white" : "text-primary-100 group-hover:text-white"
                        )}>
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className="lg:col-span-7">
                {features.map((feature) => (
                  <Tab.Panel key={feature.title} unmount={false}>
                    <div className="flex flex-col space-y-6 lg:space-y-0">
                      <div className="relative px-4 py-6 bg-white/10 rounded-xl lg:hidden">
                        <p className="text-base text-white text-center">{feature.description}</p>
                      </div>
                      <div className="w-full max-w-[55rem] mx-auto overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-primary-900/20 lg:w-[67.8125rem]">
                        <Img
                          className="w-full"
                          src={feature.image}
                          alt=""
                          sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                        />
                      </div>
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
          )}
        </Tab.Group>
      </Container>
    </section>
  );
}
