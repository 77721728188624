import React, { useState } from "react";
import { HeartIcon } from "@heroicons/react/solid";
import { HeartIcon as HeartIconOutlined, ExternalLinkIcon, PlusIcon, MinusIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import Img from "../UI/Img";
import { axiosPrivate } from "../common/axiosPrivate";
import { toast } from "react-toastify";
import useLibraryData from "../hooks/useLibraryData";

const YouTubeCard = ({ video }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { LibraryData, setLibraryData, refreshLibraryEpisode, removeLibraryEpisode, addLibraryEpisode } =
    useLibraryData();
  const [isAdded, setIsAdded] = useState(video.is_added_to_library);
  const [addedEpisodeId, setAddedEpisodeId] = useState(null);

  const AddVideoToLibrary = async () => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.post(`/youtube/${video.id}`);
      console.log("Video added to library:", response.data);
      toast.success("Video added to library successfully!");
      setIsAdded(true);
      setAddedEpisodeId(response.data.id); // Store the ID of the added episode
      setLibraryData((prevData) => ({
        ...prevData,
        episodes: [...prevData.episodes, response.data],
      }));
    } catch (error) {
      console.error("Error adding video to library:", error);
      if (error.response && error.response.status === 409) {
        toast.warning("This video is already in your library.");
        setIsAdded(true);
      } else {
        toast.error("Failed to add video to library. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const RemoveVideoFromLibrary = async () => {
    setIsLoading(true);
    try {
      await axiosPrivate.delete(`/youtube/100/${video.id}`);
      console.log("Video removed from library:", video.id);
      toast.success("Video removed from library successfully!");
      setIsAdded(false);
      setAddedEpisodeId(null);
    } catch (error) {
      console.error("Error removing video from library:", error);
      toast.error("Failed to remove video from library. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden transition-all duration-300 hover:shadow-lg mt-6 mb-8 sm:mb-6">
      <div className="p-4">
        <div className="flex flex-col sm:flex-row">
          <div className="flex-shrink-0 mb-4 sm:mb-0 sm:mr-4 flex justify-center">
            <Img
              src={video.thumbnails[0].url}
              alt={video.title}
              className="h-24 w-24 sm:h-32 sm:w-32 rounded-md object-cover shadow-sm"
            />
          </div>
          <div className="flex-grow">
            <div className="flex flex-col sm:flex-row justify-between items-start">
              <a
                href={`https://www.youtube.com/watch?v=${video.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="group mb-2 sm:mb-0">
                <h4 className="text-lg font-bold text-primary-600 group-hover:text-secondary-600 transition-colors duration-200 flex items-center">
                  {video.title.length > 50 ? video.title.substring(0, 50) + "..." : video.title}
                  <ExternalLinkIcon className="ml-1 h-5 w-5" aria-hidden="true" />
                </h4>
              </a>
              {isAdded && addedEpisodeId ? (
                <Link
                  to={`/library/${addedEpisodeId}`}
                  className="sm:ml-2 transition-all duration-200 px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 active:scale-95 flex items-center bg-green-100"
                >
                  <svg className="h-5 w-5 text-green-600 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="text-sm font-medium">View in Library</span>
                </Link>
              ) : (
                <button
                  onClick={AddVideoToLibrary}
                  disabled={isLoading}
                  className={`sm:ml-2 transition-all duration-200 px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 active:scale-95 flex items-center ${
                    isAdded ? "bg-green-100" : "bg-primary-100 hover:bg-primary-200"
                  } ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}
                  aria-label="Add to library"
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-primary-600 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    <PlusIcon className="h-5 w-5 text-primary-600 mr-2" aria-hidden="true" />
                  )}
                  <span className="text-sm font-medium">{isLoading ? "Adding..." : "Add"}</span>
                </button>
              )}
            </div>
            <h4 className="text-sm font-medium text-neutral-600">
              by{" "}
              <a target="_blank" rel="noopener noreferrer" href={video.channel_url}>
                <span className="text-secondary-600">{video.channel}</span>
              </a>
            </h4>
          </div>
        </div>
        <p className="mt-3 text-sm text-neutral-600 line-clamp-3">{video.description}</p>
        <div className="mt-4 flex justify-between items-center">
          <a
            href={`https://www.youtube.com/watch?v=${video.id.videoId}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary-600 hover:text-secondary-600 text-sm font-medium flex items-center">
            Watch on YouTube
            <ExternalLinkIcon className="ml-1 h-4 w-4" aria-hidden="true" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default YouTubeCard;
